// WesTrac

export default {
  settings: {
    disableIframePostMessage: false,
    ctaWhiteBg: "true",
  },
  content: {
    general: {
      clientName: "WesTrac",
      ampFund: true,
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          AMP Signature Super with WesTrac
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            By joining the company Superannuation fund, you benefit from group
            discount rates on fees and insurances. This means you pay much
            less than you would pay in a personal or individual plan.
          </p>
          <p>
            This website provides all the information you need around the
            WesTrac Superannuation Fund. There are helpful super
            tools and calculators, and information on other services we offer
            if you require additional support with your financial well-being.
          </p>
          <div class="col-lg-6 pt-4 pb-1 px-0 text-center text-lg-center">
            <a
              href="#/#docs"
              class="btn-learn-more"
            >
              Learn more about the super fund
            </a>
          </div>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p><b>Fees (per annum)</b></p>
          <ul>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Member Fee:
              <b>Nil</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Administration Fee:
              <b>0.14%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Trustee fee:
              <b>0.015%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> MySuper Investment
              Fee:
              <b>0.26%</b>
            </li>            
            <li>
              <i class="ri-money-dollar-circle-line"></i> Other MySuper Investment Costs: <b>0.10% - 0.35%</b>
            </li>
          </ul>
          <small>
            Investment Fees and Costs are deducted from investment returns prior to calculation of daily unit prices. For full fee disclosure refer to the Fund Product Disclosure Statement (PDS).
          </small>
          <p>
            <b>Insurances (Death & Total Permanent Disability)</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Category 1 – Directors & Executives</strong>
              20% x your salary x number of years to age 65
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Category 2 – Staff</strong>
              15% x your salary x number of years to age 65
            </li>
          </ul>
          <p class="mt-2">
            <b>Investment Options</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Default Investment Option:</strong>
              AMP MySuper Lifecycle
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Investment returns available at:</strong>
              <a href="https://www.amp.com.au/amp/performance-and-unit-prices/superannuation-funds/signaturesuper" target="_blank">AMP Signature Super</a>
            </li>
          </ul>
        </div>
      </div>
      `,
    pdfs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Product Disclosure Statement",
        description: "In-depth information on your super fund.",
        type: "info",
        url: "AMP_pds.pdf",
      },
      {
        title: "Member Guide Fact Sheet",
        description: "Additional information on your super fund.",
        type: "form",
        url: "AMP_SS_Member_Guide_01042024.pdf",
      },
      {
        title: "Beneficiary Nomination",
        description:
          "Do you need to nominate a beneficiary on your super fund?",
        type: "form",
        url: "AMP_Beneficiary_Form_11102023.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "Additional Insurance Cover",
        description:
          "Use this form to apply for additional Death and if applicable Total and Permanent Disablement insurance cover.",
        type: "form",
        url: "AMP_Additional_Insurance_Form_16032024.pdf",
      },
      {
        title: "Investment Selection",
        description: "Do you want to change your investment choice?",
        type: "form",
        url: "AMP_Investment_option_form_03032023.pdf",
      },
      {
        title: "Investment Guide Fact Sheet",
        description: "Additional information on your super fund.",
        type: "form",
        url: "AMP_SS_Investment_Guide_01042024.pdf",
      },
      {
        title: "Join the Westrac fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "Westrac-New_Employee_Pack-October_2023.pdf",
      },
      {
        title: "AMP Plan Summary",
        description:
          "The information in this Plan Summary describes the features and benefits specific to WesTrac Superannuation Plan.",
        type: "info",
        url: "Westrac_Superannuation_Plan-Staff-April_2024.pdf",
      },
    ],
    optInLink: "https://secure.amp.com.au/ddc/public/ui/pmif/",
    teamMembers: [
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
      {
        teamMemberPic: "silvia.png",
        name: "Silvia Pothoven",
        phoneHref: "0292422117",
        phone: "02 9242 2117",
        email: "silvia_pothoven@ajg.com.au",
        social: "https://www.linkedin.com/in/silviainfante",
      },
      {
        teamMemberPic: "sharron.jpg",
        name: "Sharron Wharton-Street",
        phoneHref: "0862508493",
        phone: "08 6250 8493",
        email: "Sharron_WhartonStreet@ajg.com.au",
        social: "https://www.linkedin.com/in/sharron-wharton-street-801026161/",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
    ],
    footerClientName: "WesTrac",
  },

  superscore: true,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
